.configuration {
  position: relative;
  display: flex;
  align-items: center;
  z-index: 2;
  font-size: 1rem;
  height: 24px;
  padding: 16px;
  background: #fff;
  box-sizing: content-box;
}
.configuration .stepNumber {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  color: #fff;
  background-color: #346e9f;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
}
.configuration .stepTitle {
  line-height: 24px;
  margin-left: 1.142857142857143em;
}
/* Additional Style for area view in Mobile Version */
.shadowArea {
  position: absolute;
  background-color: #fff;
  margin-top: 8px;
  margin-left: 10px;
  height: 40px;
  width: 120px;
  padding: 10px;
  border-radius: 20px;
  box-shadow: 0px 1px 3px rgba(0,0,0,0.2), 0px 2px 2px rgba(0,0,0,0.12), 0px 0px 2px rgba(0,0,0,0.14);
  box-sizing: border-box;
}
@media screen and (min-width: 700px) {
  .shadowArea {
    position: static;
    margin: 0;
    margin-top: 0;
    padding: 1.142857142857143em;
    width: auto;
    height: 24px;
    border-radius: 0;
    box-shadow: none;
    box-sizing: content-box;
  }
}
