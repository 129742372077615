.graphContainer {
  display: flex;
}
.graphContainerDisplayColumn {
  flex-direction: column;
}
.legend {
  margin-left: 20px;
  width: 200px;
}
