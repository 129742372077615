/**
 * The awesomplete autocomplete
 */
:global .awesomplete {
  font-size: 1rem;
  display: inline-block;
  position: relative;
  height: 3.571428571428572em;
  width: 100%;
  flex-shrink: 1;
  flex-grow: 1;
}
:global .awesomplete [hidden] {
  display: none;
}
:global .awesomplete .visually-hidden {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}
:global .awesomplete > input {
  display: block;
  width: 100%;
  padding: 18.5px 0 18.5px 14px;
}
:global .awesomplete > label {
  transform: translate(14px, -12px) scale(0.75);
}
:global .awesomplete > ul {
  position: absolute;
  left: 0;
  z-index: 1;
  min-width: 100%;
  max-height: 28.571428571428573em;
  overflow-y: auto;
  overflow-x: hidden;
  box-sizing: border-box;
  list-style: none;
  padding: 0;
  margin: -0.285714285714286em 0 0 0;
  background: #fff;
  box-shadow: 0 1px 1px rgba(0,0,0,0.1);
  -webkit-overflow-scrolling: touch;
}
:global .awesomplete > ul:empty {
  display: none;
}
:global .awesomplete > ul > li {
  position: relative;
  padding: 0.5em 0.714285714285714em;
  cursor: pointer;
  transition: all 0.25s ease-out;
}
:global .awesomplete > ul > li:hover {
  background: #f2f2f2;
  color: #000;
}
:global .awesomplete > ul > li[aria-selected='true'] {
  background: #346e9f;
  color: #fff;
}
:global .awesomplete mark {
  background-color: transparent;
  font-weight: bold;
  color: inherit;
}
