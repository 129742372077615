.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: scroll;
  background: #fff;
  color: #565656;
  z-index: 1;
  min-width: 250px;
}
@media screen and (min-width: 700px) {
  .container {
    max-width: 400px;
    box-shadow: rgba(0,0,0,0.4) 0px 1px 4px 1px;
    overflow: auto;
  }
}
.menuSeparator {
  margin-left: 1.714285714285714em;
  margin-right: 1.714285714285714em;
  margin-top: 1.142857142857143em;
  margin-bottom: 1.142857142857143em;
  border-bottom: 1px solid #808080;
}
.containerMenuActions {
  flex: 0;
}
@media screen and (min-width: 700px) {
  .containerMenuActions {
    flex: 1;
  }
}
