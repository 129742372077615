.locationCompetition {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  font-size: 10px;
  box-shadow: 0 0 2px rgba(0,0,0,0.2);
  background: #fff;
  padding: 1.142857142857143em;
  pointer-events: auto;
  color: #808080;
  z-index: 1;
  order: 3;
}
@media screen and (min-width: 700px) {
  .locationCompetition {
    min-width: 254px;
    position: absolute;
    top: 0;
    right: 0;
  }
}
.locationCompetitionPoisLegend {
  width: 66.6%;
  top: 1.428571428571429rem;
  padding: 1em 1.142857142857143em;
  pointer-events: auto;
}
.locationCompetitionPoisLegend .legend {
  margin: 0;
  text-align: right;
}
.locationCompetitionPoisLegendAttribution {
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-112%);
  pointer-events: auto;
  white-space: nowrap;
}
.locationCompetitionPoisLegendAttributionBy {
  font-size: 0.714285714285714em;
}
.colorGrey {
  color: #808080;
}
.locationCompetitionLegendCloseButton {
  background-color: #fff;
  border: none;
  border-radius: 50%;
  margin-right: 6.857142857142858px;
}
@media screen and (min-width: 700px) {
  .locationCompetitionLegendCloseButton {
    margin-right: 23.857142857142858px;
  }
}
