.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  background: #fff;
  height: 100%;
  overflow: hidden;
  padding: 16px;
}
.fields {
  display: flex;
  flex-direction: column;
}
