.container {
  display: block;
  width: 100%;
  background: #f2f2f2;
  padding: 1.428571428571429rem 1.714285714285714em;
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #808080;
}
.container .content {
  display: block;
}
.container .data {
  display: block;
  flex-shrink: 0;
  flex-grow: 0;
}
.container .entry {
  font-size: 0.714285714285714em;
  color: #808080;
  display: inline-block;
  margin-right: 0.571428571428571em;
  vertical-align: middle;
}
.container .title {
  color: #000;
  font-size: 1rem;
  display: flex;
  flex-shrink: 1;
  flex-grow: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  height: 2.5rem;
  line-height: 2.5rem;
}
.container .icon {
  color: #565656;
}
.section {
  width: 100%;
  margin: 4em 2em;
  border: none;
  display: block;
}
