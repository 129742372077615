.container {
  width: 100%;
  height: 100%;
  order: 0;
  flex: 1;
}
@media screen and (min-width: 700px) {
  .container {
    flex: 2;
    display: block;
    order: 2;
  }
}
.canvas {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.noDisplay {
  display: none;
}
.printMap {
  height: 100%;
}
