.container {
  box-shadow: rgba(0,0,0,0.4) 0px 1px 4px 1px;
  position: sticky;
  position: -webkit-sticky;
  bottom: 0;
  z-index: 5;
  width: 100%;
  border-left: 4px solid #f5bb18;
  background: #f2f2f2;
  height: 60px;
}
@media screen and (min-width: 700px) {
  .container {
    display: none;
  }
}
.container .arrowIcon {
  display: flex;
}
