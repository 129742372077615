.option {
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
}
.option .image {
  width: 40%;
  height: 83px;
  flex-shrink: 0;
  flex-grow: 0;
  background-position: center center;
  background-size: cover;
}
.option .data {
  width: 55%;
  font-size: emCalc(12px);
}
.note {
  color: $grey;
}
