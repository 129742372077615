/**
 * The traffic reachability legends view
 */
.containerTopLegendReachability {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-indent: 8px;
}
@media screen and (min-width: 700px) {
  .containerTopLegendReachability {
    padding-top: 6px;
  }
}
.trafficReachabilityLegendTtile {
  left: 24px;
  font-size: 14px;
  line-height: 18px;
}
.trafficReachabilityLegendCloseButton {
  background-color: #fff;
  border: none;
  border-radius: 50%;
  margin-right: 6.857142857142858px;
}
@media screen and (min-width: 700px) {
  .trafficReachabilityLegendCloseButton {
    margin-right: 23.857142857142858px;
  }
}
.trafficReachability {
  font-size: 1rem;
  pointer-events: none;
  z-index: 1;
  order: 3;
}
.trafficReachability .trafficReachabilityLegend {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 10px;
  box-shadow: 0 0 2px rgba(0,0,0,0.2);
  background: #fff;
  padding: 1.142857142857143em;
  pointer-events: auto;
  z-index: 1;
}
@media screen and (min-width: 700px) {
  .trafficReachability .trafficReachabilityLegend {
    min-width: 254px;
    top: 0;
    right: 0;
    bottom: auto;
    width: auto;
  }
}
.trafficReachability .trafficReachabilityLegend .trafficReachabilityLegendPois {
  margin-top: 0.5em;
}
.trafficReachability .trafficReachabilityLegend .trafficReachabilityLegendPois .legend {
  text-align: right;
}
.trafficReachability .trafficReachabilityAttribution {
  position: absolute;
  bottom: 0;
  transform: translateX(21%);
  pointer-events: auto;
  font-size: 0.714285714285714em;
  white-space: nowrap;
}
.trafficReachability dl {
  margin: 0;
}
.colorGrey {
  color: #808080;
}
