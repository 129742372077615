.container {
  font-size: 1rem;
  background: #fff;
  z-index: 5;
  flex: 2;
  overflow-x: scroll;
  border-left: 1px solid #565656;
  order: 2;
}
.header {
  background: #f2f2f2;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  padding: 16px;
}
.contentContainer {
  padding: 16px;
}
.tabContainer {
  padding: 0 16px;
  border-bottom: 1px solid #f2f2f2;
}
.note {
  color: #808080;
}
.hideOnMobile {
  display: none;
}
@media screen and (min-width: 700px) {
  .hideOnMobile {
    display: block;
  }
}
.name {
  text-transform: capitalize;
  white-space: nowrap;
}
.tableHeader {
  max-width: 170px;
}
