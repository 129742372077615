.legend {
  font-size: 1rem;
  margin-top: 1.428571428571429rem;
}
.legend:last-child {
  margin-bottom: 0;
}
.legendEntry {
  display: flex;
  font-size: 1rem;
}
.legendEntry:last-child {
  margin-bottom: 15px;
}
.legendColor {
  display: inline-block;
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  margin: 0.5em 0.45em;
  vertical-align: middle;
}
.legendIcon {
  display: inline-block;
  width: 1.714285714285714em;
  height: 1.714285714285714em;
  margin-right: 0.142857142857143em;
  vertical-align: middle;
}
.legendIconSmall {
  width: 0.642857142857143em;
  height: 0.642857142857143em;
  margin: 0.6em 0.6em 0.4em 0.4em;
}
.legendIconSmall img {
  vertical-align: top;
}
.legendDescription {
  margin: 0.4em 1em 0 0;
  vertical-align: middle;
}
@media screen and (min-width: 700px) {
  .legendDescription {
    display: inline-block;
  }
}
