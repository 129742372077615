.container {
  font-size: 0.928571428571429rem;
  color: #346e9f;
  background: transparent;
  transition: color 0.25s ease-out, background 0.25s ease-out, border 0.25s ease-out;
}
.regular {
  height: 2.769230769230769em;
  line-height: 2.769230769230769em;
  border: 1px solid currentColor;
  text-transform: uppercase;
  padding: 0 1.230769230769231em;
  margin: 0 auto;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.regular[disabled] {
  color: #ccc;
}
.regular:hover:not([disabled]),
.regular:focus:not([disabled]) {
  background: #e6e6e6;
}
.regular:active:not([disabled]) {
  background: #d6dee6;
}
.link {
  height: 2.461538461538462em;
  line-height: 2.461538461538462em;
  padding: 0;
  border: none;
  border-bottom: 1px solid transparent;
  text-align: left;
}
.link:hover:not([disabled]),
.link:focus:not([disabled]) {
  color: #2f638f;
  border-bottom-color: #346e9f;
}
.link span {
  flex-grow: 1;
  flex-shrink: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.grow {
  flex: 1;
  margin: 0;
}
.marginLeft {
  margin-left: 1em;
}
.fullWidth {
  width: 100%;
}
.withIcon {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
}
.withIcon svg {
  flex-grow: 0;
  flex-shrink: 0;
  margin: 0 0.714285714285714em;
}
.icon {
  font-size: 1rem;
  width: 2.285714285714286em;
  height: 2.285714285714286em;
  line-height: 0;
  padding: 0;
  margin: 0;
  border: none;
  background: transparent;
  text-align: center;
  vertical-align: bottom;
  transition: background 0.25s ease-out;
}
.icon[disabled] svg {
  fill: #ccc;
}
.icon:not([disabled]):hover,
.icon:not([disabled]):focus {
  background: #e6e6e6;
}
.white {
  color: #fff;
}
.white svg {
  fill: #fff;
}
