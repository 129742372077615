.container {
  display: flex;
  align-items: center;
  background: transparent;
  width: 100%;
}
.container:hover,
.container:focus {
  outline: 0;
  color: inherit;
}
@media screen and (min-width: 700px) {
  .container:hover,
  .container:focus {
    background: #f2f2f2;
  }
}
.menuItemButton {
  display: block;
  color: inherit;
  background: transparent;
  border: none;
  padding: 0 1.714285714285714em;
  height: 4.571428571428571em;
  margin: 0;
  width: 100%;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}
.menuItemButtonInfo {
  background: transparent;
  width: 3.714285714285714em;
  height: 4em;
  padding: 0;
  margin: 0;
  border: none;
  text-align: center;
  vertical-align: bottom;
}
.menuItemButtonInfo:hover,
.menuItemButtonInfo:focus {
  outline: 0;
}
.menuItemButtonInfo:hover svg,
.menuItemButtonInfo:focus svg {
  height: 1.428571428571429em;
  width: 1.428571428571429em;
  fill: #f5bb18;
}
.menuItemButtonInfo svg {
  height: 1.428571428571429em;
  width: 1.428571428571429em;
  fill: #346e9f;
  transition: fill 0.25s ease-out;
}
