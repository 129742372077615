.container {
  background: #fff;
  z-index: 5;
  flex: 1;
  overflow: scroll;
}
.container input:invalid {
  box-shadow: 0 0 1.5px 1px #f00;
  -moz-box-shadow: none;
}
@media screen and (min-width: 700px) {
  .container {
    border-left: 1px solid #565656;
    order: 2;
    font-size: 1rem;
    flex: 2;
  }
}
.container .header {
  background: #f2f2f2;
  width: 100%;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.contentContainer {
  padding: 16px;
  margin: 0 auto;
}
.tabContainer {
  padding: 0 16px;
  border-bottom: 1px solid #f2f2f2;
}
