/**
 * The ecology overlays
 */
.container {
  box-shadow: rgba(0,0,0,0.4) 0px 1px 4px 1px;
  font-size: 1rem;
  min-width: 254px;
  align-self: flex-start;
  display: flex;
  align-items: center;
  background: #346e9f;
  color: #fff;
  padding: 0.5em 1.142857142857143em;
  z-index: 1;
}
@media screen and (min-width: 700px) {
  .container {
    max-width: 400px;
  }
}
@media screen and (min-width: 1200px) {
  .container {
    min-width: 254px;
    max-width: none;
  }
}
.container div {
  margin-right: 2%;
}
@media screen and (min-width: 700px) {
  .container div {
    margin-right: 0;
  }
}
