.container {
  display: flex;
  flex: 1;
  line-height: 1.714285714285714em;
}
.container a {
  color: #f5bb18;
}
.container a:active,
.container a:hover {
  color: #b48708;
}
.header {
  height: 4em;
  line-height: 4em;
  flex-shrink: 0;
  flex-grow: 0;
  display: flex;
  align-items: center;
}
.header .backButton {
  display: flex;
  align-items: center;
  padding: 0 0.642857142857143em 0 1.142857142857143em;
}
.header .icon {
  color: #346e9f;
}
.contentContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.content {
  flex: 1;
  background: #346e9f;
  color: #fff;
  padding: 1.714285714285714em;
}
.printLandscapeDisclaimer {
  font-weight: 900;
}
.buttonGroup {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 100px;
}
.buttonGroup button {
  margin-bottom: 10px;
}
