.instruction {
  padding: 16px;
  background: #346e9f;
  color: #fff;
  font-size: 14px;
  line-height: 18px;
}
.instruction .additionalTextDesktop {
  display: none;
}
@media screen and (min-width: 700px) {
  .instruction .additionalTextDesktop {
    display: block;
  }
}
.highlight {
  font-weight: bold;
}
