.container {
  flex: 1;
  display: flex;
  flex-direction: column;
  background: #fff;
  overflow: hidden;
  padding: 16px;
}
.container .recentDevelopmentButton {
  margin-top: 20px;
}
.container .recentDevelopmentButton svg {
  fill: #fff;
}
