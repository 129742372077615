.container {
  background: #fff;
  flex: 1;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  z-index: 1;
  order: 1;
  overflow: hidden;
  min-width: 250px;
}
@media screen and (min-width: 700px) {
  .container {
    max-width: 400px;
    box-shadow: rgba(0,0,0,0.4) 0px 1px 4px 1px;
  }
}
.image {
  min-height: 150px;
  margin: 0 2.857142857142857em;
  background: url("f123c3c8de676728340785356023b87e.png") no-repeat center center;
  background-size: contain;
}
.content {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: auto;
}
.text {
  color: #808080;
  padding: 0 2.857142857142857em;
  margin-bottom: 5.714285714285714em;
}
.text a {
  color: #f5bb18;
}
.text a:hover,
.text a:focus {
  color: #b48708;
}
.buttonsContainer {
  padding: 1.428571428571429rem;
  background: #fff;
  border-top: 2px solid #f5bb18;
  display: flex;
  text-align: center;
  position: sticky;
  flex-wrap: wrap;
}
.buttonCursor {
  cursor: pointer;
}
