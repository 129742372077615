.container {
  position: absolute;
  right: 0;
  z-index: 1;
}
.content {
  display: flex;
  flex-direction: column;
}
.deleteButtonContainer {
  align-self: flex-end;
}
.deleteButtonContainer span {
  color: #fff;
}
/* Info button hover effects */
.infoButtonContainer:hover:not([disabled]) .infoButton,
.infoButtonContainer:focus:not([disabled]) .infoButton {
  color: #b48708;
  cursor: pointer;
}
.infoButtonContainer:hover:not([disabled]) .infoButtonIcon,
.infoButtonContainer:focus:not([disabled]) .infoButtonIcon {
  fill: #b48708;
}
/* Delete button hover effects */
.deleteButtonContainer:hover:not([disabled]) .deleteButton,
.deleteButtonContainer:focus:not([disabled]) .deleteButton {
  color: #b48708;
  border-bottom-color: #f5bb18;
  cursor: pointer;
}
.deleteButtonContainer:hover:not([disabled]) .deleteButtonIcon,
.deleteButtonContainer:focus:not([disabled]) .deleteButtonIcon {
  fill: #b48708;
  margin-left: 0;
}
.deleteButtonContainer:hover:not([disabled]) span,
.deleteButtonContainer:focus:not([disabled]) span {
  color: #b48708;
}
