.controlButtonContainer {
  position: absolute;
  top: 152px;
  right: 8px;
  z-index: 1;
}
@media screen and (min-width: 700px) {
  .controlButtonContainer {
    right: 25px;
    bottom: 25px;
    top: auto;
  }
}
.controlButtonContainerHeightLegendOpen {
  top: 25px;
  bottom: auto;
}
.zoomControlContainer {
  display: none;
}
@media screen and (min-width: 700px) {
  .zoomControlContainer {
    display: flex;
    flex-direction: column;
    min-height: 92px;
    box-shadow: rgba(0,0,0,0.4) 0px 1px 4px 1px;
  }
}
.zoomButtonLine {
  content: '';
  position: absolute;
  width: 36px;
  height: 2px;
  right: 6px;
  bottom: 120px;
  z-index: 2;
  background: #e6e6e6;
}
.container {
  position: absolute;
  right: 0;
  top: 234px;
  width: 200px;
  z-index: 1;
}
@media screen and (min-width: 700px) {
  .container {
    bottom: 25px;
    top: auto;
    right: 60px;
    width: 33.3%;
    height: 48px;
    display: flex;
    justify-content: center;
  }
}
@media screen and (min-width: 1200px) {
  .container {
    right: 102px;
    width: 66.6%;
  }
}
.containerHeightLegendOpen {
  top: 108px;
}
.buttonContainer {
  display: flex;
  box-shadow: rgba(0,0,0,0.4) 0px 1px 4px 1px;
}
@media screen and (min-width: 700px) {
  .buttonContainer button {
    cursor: pointer;
  }
}
.button {
  flex: 2;
  position: relative;
  font-size: 14px;
  text-align: center;
  height: 48px;
  color: #808080;
  background-color: #fff;
  padding: 7px 8px 9px 8px;
  min-width: 72px;
  border: none;
}
@media screen and (min-width: 700px) {
  .button {
    flex: 50%;
    min-width: 102px;
  }
}
.active {
  color: #000;
  background-color: #f2f2f2;
}
@media screen and (min-width: 700px) {
  .active {
    border-bottom: 2px solid #f5bb18;
  }
}
.roundedBorders {
  border-radius: 30px 0 0 30px;
}
@media screen and (min-width: 700px) {
  .roundedBorders {
    border-radius: 0;
  }
}
