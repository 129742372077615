/**
 * Loading component
 */
.loading {
  font-size: 1rem;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: transparent;
  background: rgba(255,255,255,0.7);
  z-index: 100;
}
.loading .bubble {
  background-color: #000;
  border-radius: 50%;
  display: inline-block;
  height: 0.75em;
  margin-left: 0.5em;
  width: 0.75em;
  animation-name: button-loading-animation;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-direction: linear;
}
.loading .bubble.one {
  animation-delay: 0.3s;
}
.loading .bubble.two {
  animation-delay: 0.6s;
}
.loading .bubble.three {
  animation-delay: 0.9s;
}
@-moz-keyframes button-loading-animation {
  50% {
    background-color: currentColor;
  }
}
@-webkit-keyframes button-loading-animation {
  50% {
    background-color: currentColor;
  }
}
@-o-keyframes button-loading-animation {
  50% {
    background-color: currentColor;
  }
}
@keyframes button-loading-animation {
  50% {
    background-color: currentColor;
  }
}
