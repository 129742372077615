/**
 * The ecology overlays
 */
.container {
  display: flex;
  align-self: right;
  font-size: 1rem;
  pointer-events: none;
  z-index: 1;
  order: 3;
}
.headerContainer {
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 1;
  flex: 1;
  display: flex;
  align-content: flex-start;
  flex-direction: column;
}
@media screen and (min-width: 700px) {
  .headerContainer {
    flex: 0;
    right: 0;
    bottom: auto;
    width: auto;
  }
}
@media screen and (min-width: 1200px) {
  .headerContainer {
    flex-direction: row;
  }
}
.ecology {
  display: flex;
  flex: 1;
  justify-content: space-between;
  font-size: 10px;
  box-shadow: 0 0 2px rgba(0,0,0,0.2);
  background: #fff;
  padding: 1.142857142857143em;
  pointer-events: auto;
}
@media screen and (min-width: 700px) {
  .ecology {
    align-self: flex-end;
    min-width: 254px;
  }
}
@media screen and (min-width: 1200px) {
  .ecology {
    flex: 0;
  }
}
.hideHeader {
  display: none;
}
@media screen and (min-width: 700px) {
  .hideHeader {
    display: flex;
    visibility: hidden;
  }
}
.description {
  padding: 6px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.colorGrey {
  color: #808080;
}
.printMapContainer {
  position: relative;
}
.attribution {
  position: absolute;
  bottom: 0;
  pointer-events: auto;
  font-size: 0.714285714285714em;
  color: #808080;
  white-space: nowrap;
}
.attribution img {
  display: block;
}
.attributionEcologyLandscapeProtection {
  transform: translateX(39%);
}
@media screen and (min-width: 1200px) {
  .attributionEcologyLandscapeProtection {
    transform: translateX(18%);
  }
}
.attributionEcologySpeciesProtection {
  transform: translateX(44%);
}
@media screen and (min-width: 1200px) {
  .attributionEcologySpeciesProtection {
    transform: translateX(18%);
  }
}
.attributionHighVoltage {
  transform: translateX(16%);
}
.layerPrintLegend {
  display: flex;
  vertical-align: middle;
}
.layerLegend {
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  margin: 0;
}
.layerLegendIcon {
  display: inline-block;
  width: 1.285714285714286em;
  height: 1.285714285714286em;
  margin-right: 0.5em;
  vertical-align: middle;
}
.layerLegendColor {
  display: inline-block;
  width: 1em;
  height: 1em;
  margin-right: 0.5em;
  vertical-align: middle;
}
.layerLegendDescription {
  display: inline-block;
  margin: 0 1em 0 0;
  vertical-align: middle;
}
.legendCloseButton {
  background-color: #fff;
  border: none;
  border-radius: 50%;
  margin-right: 6.857142857142858px;
}
@media screen and (min-width: 700px) {
  .legendCloseButton {
    margin-right: 23.857142857142858px;
  }
}
