.container {
  z-index: 2;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  order: 1;
  min-width: 250px;
}
@media screen and (min-width: 700px) {
  .container {
    max-width: 400px;
    box-shadow: rgba(0,0,0,0.4) 0px 1px 4px 1px;
    background: #fff;
  }
}
.noDisplay {
  display: none;
}
