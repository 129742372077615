.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  transition: 0.25s height ease-out;
  background: #fff;
  color: #565656;
}
.active {
  color: #000;
  background: #f2f2f2;
}
@media screen and (min-width: 700px) {
  .active {
    border-left: 4px solid #f5bb18;
  }
}
