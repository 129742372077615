.container {
  font-size: 1rem;
  background: #fff;
  display: flex;
  flex-direction: column;
  z-index: 5;
  overflow: scroll;
  border-left: 1px solid #565656;
  flex: 2;
}
@media screen and (min-width: 700px) {
  .container {
    order: 2;
  }
}
.container .header {
  background: #f2f2f2;
  width: 100%;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.contentContainer {
  padding: 32px;
}
.contentContainer > div {
  column-width: 28.571428571428573em;
  column-gap: 2.857142857142857em;
  column-count: 2;
}
.tabContainer {
  padding: 0 16px;
  border-bottom: 1px solid #f2f2f2;
}
.selectedTab {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
}
.youtube {
  position: relative;
  overflow: hidden;
  padding-top: 56.25%;
  margin: 20px;
}
.youtube iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
.buttonGroup {
  text-align: center;
  padding-bottom: 3em;
}
