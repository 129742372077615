.container {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  order: 2;
  z-index: 1;
  overflow: hidden;
}
@media screen and (min-width: 700px) {
  .container {
    flex-direction: row;
    overflow: auto;
    order: 1;
  }
}
@media screen and (min-width: 1200px) {
  .container {
    order: 1;
  }
}
